.header-row {
    background-color: #00A86B; /* Adjust as needed */
    padding: 1rem;
    align-items: center;
}
  
.header-image {
display: flex;
justify-content: center;
align-items: center;
}

.login-container {
    height: 100vh;
  }
  
.login-image {
background-image: url('../assets/loginPage.svg'); /* Adjust the path based on your directory structure */
background-size: cover;
background-position: center;
height: 75%;
margin-top: 10px !important;
}
  
.signup-image {
    background-image: url('../assets/signup.svg'); /* Adjust the path based on your directory structure */
    background-size: cover;
    background-position: center;
    height: 75%;
    margin-top: 10px !important;
}

.forgotPassword-image {
    background-image: url('../assets/forgotPassword.svg'); /* Adjust the path based on your directory structure */
    background-size: cover;
    background-position: center;
    height: 65%;
    margin-top: 10px !important;
}

.login-form {
background-color: white;
padding: 2rem;
}

.primaryBtn {
background-color: #00A86B !important;
color: #FFFFFF;
}
  

